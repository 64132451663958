import React from 'react'
import Layout from '../components/layout'

const BlogPage = () => {
    return (
        <Layout>
            <h1> Check out our latest blog posts</h1> 
        </Layout>
    )
}

export default BlogPage